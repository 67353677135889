import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import PrimaryCta from "./primary-cta"
import baremeFile from '../../static/file/Barème-des-honoraires-PF.pdf'

const Footer = ({ data, hideNewsletter }) => {

  return (
    <>
      <div id="footer">
        { !hideNewsletter && (
          <div className="footer--section cta-section">
            <h3 dangerouslySetInnerHTML={{ __html: data.titre }} />
            <div className="footer--cta-container">
              <PrimaryCta link={data.cta.lien} text={data.cta.texte}/>
            </div>
          </div>
        )}
        <div className="footer--section content">
          <div className="column description">
            <h5>{data.entrepriseNom}</h5>
            <p>{data.description}</p>
          </div>
          <div className="column links">
            <h5>{data.planDuSite}</h5>
            <ul id="links-container">
              {data.pages.map((object, i) => (
                <li key={i}>
                  <Link to={object.path}>{object.texte}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="column social-media">
            <h5>{data.reseauxSociaux}</h5>
            {data.reseauxSociauxIcone.map((object, i) => (
              <a href={object.path} key={i}>
                <img srcSet={object.icone.file.url} alt={object.icone.description} />
              </a>
            ))}
          </div>
        </div>
        <div className="footer--section legals">
          <p>{data.copyrights}</p>
          <div className="legals-right">
            <Link to={data.mentionsLegales.path}>{data.mentionsLegales.texte}</Link>
            <a href={baremeFile} download>Barème des honoraires d'agence 2023</a>
          </div>
        </div>
      </div>
    </>
)}

export default ({ data, hideNewsletter }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulFooter {
          nodes {
            titre
            description
            copyrights
            planDuSite
            entrepriseNom
            reseauxSociaux
            reseauxSociauxIcone {
              path
              icone {
                description
                file {
                  url
                }
              }
            }
            pages {
              path
              texte
            }
            mentionsLegales {
              texte
              path
            }
            cta {
              texte
              lien
            }
          }
        }
      }
    `}
    render={data => <Footer data={data.allContentfulFooter.nodes[0]} hideNewsletter={hideNewsletter} />}
  />
)

Footer.propTypes = {
  data: PropTypes.object.isRequired,
}
