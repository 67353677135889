import React from "react"

import { Link } from "gatsby"


const PrimaryCta = ({ text, link, disabled, scrollTo, ...props }) => (
  <>
    { scrollTo ?
      <div id="primary-cta" className={`button button--animated ${disabled && "disabled"}`} onClick={() => document.getElementById(scrollTo).scrollIntoView({behavior:"smooth", block: "nearest"})} {...props}><span>{text}</span></div> :
      <Link to={link} id="primary-cta" className={`button button--animated ${disabled && "disabled"}`} {...props}><span>{text}</span></Link>
    }
  </>
)


export default PrimaryCta
