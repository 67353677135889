import React from "react"
import Helmet from "react-helmet"

import Footer from "./footer"
import Header from "./header"
import Head from "./head"

import "@fontsource/manrope"
import "@fontsource/manrope/600.css"
import "@fontsource/manrope/400.css"
import "@fontsource/manrope/700.css"

export default function Layout({ children, path, color, hideNewsletter, seo, disablePrimaryCta }) {

  const head = seo || <Head />;

  return (
    <div id="layout-container" style={color && {backgroundColor: color}}>
      {head}
      <main>
        <div id="header-container">
          <Header path={path} disablePrimaryCta={disablePrimaryCta}/>
        </div>
        {children}
        <Footer hideNewsletter={hideNewsletter} />
      </main>
    </div>
  )
}
