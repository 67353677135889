import React from "react"

import Portal from "./portal"
import PrimaryCta from "./primary-cta"
import { Link } from "gatsby"


const OverlayMenu = ({pages, pagesLocation, onNavigate, cta}) => {

  const filteredPages = pages.filter(page => page.texte.toLowerCase() !== "location")

  return (
    <Portal children={
      <div id="overlay-menu-container">
        <div className="container">
          <ul>
            {filteredPages.map((object, i) => (
              <li key={i}>
                <Link to={object.path} onClick={() => onNavigate()}>
                  <h5>{object.texte}</h5>
                </Link>
              </li>
            ))}
            {pagesLocation.map((object, i) => (
              <li key={i}>
                <Link to={object.path} onClick={() => onNavigate()}>
                  <h5>{object.texte}</h5>
                </Link>
              </li>
            ))}
          </ul>
          <div id="menu--cta-container">
            <PrimaryCta text={cta.texte} link={cta.lien}/>
          </div>
        </div>
      </div>
    }/>
  )
}

export default OverlayMenu