import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import OverlayMenu from "./overlay-menu"
import PrimaryCta from "./primary-cta"

class Header extends Component {

  constructor(props) {
    super(props);

    let logoHidden;
    if (typeof window !== 'undefined') {
      logoHidden = sessionStorage.getItem('logo-header-hidden');
    } else {
      logoHidden = false;
    }

    if (this.props.path === "/") {
      logoHidden = false;
    }

    if (this.props.path !== "/") {
      logoHidden = true;
    }

    this.state = {isMenuDisplayed: false, path: this.props.path, logoHidden: logoHidden}
  }

  componentDidMount() {
    const {logoHidden} = this.state;
    let headerContainer = document.getElementById("header-container")

    window.addEventListener("scroll", event => {

      if (!logoHidden) {
        this.setState({logoHidden: true})
        if (typeof window !== 'undefined') {
          sessionStorage.setItem('logo-header-hidden', true);
        }
      }

      if (window.pageYOffset > 0) {
        headerContainer.classList.add("sticky")
        return
      }

      headerContainer.classList.remove("sticky")
    })

    window.addEventListener('resize', () => {
        this.setState({isMenuDisplayed: false});
    });
  }

  onClickBurgerMenu() {
    const {isMenuDisplayed} = this.state;
    this.setState({isMenuDisplayed: !isMenuDisplayed})
  }

  render() {
    const {
      data: { cta, pages, logo, pagesLocation, logoHeader },
      disablePrimaryCta
    } = this.props

    const {
      isMenuDisplayed,
      path,
      logoHidden
    } = this.state;

    return (
      <div id="header">
        <div className="logo-collapse" style={{display: !logoHidden ? 'flex' :'None'}}>
          <img srcSet={logoHeader.file.url} alt="" className="logo"/>
        </div>
        <div id="header-container">
          <Link to="/" className={!logoHidden && "hidden"}>
            <img srcSet={logo.file.url} alt="" className="logo"/>
          </Link>
          <ul>
            {pages.map((object, i) => (
              <> {object.texte.toLowerCase() === "location" ? (
                <div className="dropdown-menu">
                  <li key={i} className={(typeof window !== 'undefined' && document.URL.includes("louer")) ? "active" : undefined }><a className="link">Location</a></li>
                  <div className="dropdown-menu-content">
                    {pagesLocation.map((objectChild, i) => (
                      <div className="dropdown-menu-item">
                        <Link to={objectChild.path} className="link animated" title={objectChild.texte}>{objectChild.texte}</Link>
                      </div>
                    ))}
                  </div>
                </div>
              ):(
                <li key={i} className={object.path === path || (object.path.includes("acheter") && (typeof window !== 'undefined' && document.URL.includes("acheter"))) ? "active" : undefined }>
                  <Link to={object.path} state={{ idx: i }} className="link animated" title={object.texte}>{object.texte}</Link>
                </li>
              )}
              </>
            ))}
          </ul>
          <div className="cta-container">
            <PrimaryCta link={cta.lien} text={cta.texte} disabled={disablePrimaryCta}/>
          </div>
          <div className={`burger-menu ${isMenuDisplayed ? "collapsed" : ""}`} onClick={() => this.onClickBurgerMenu()}>
            <span/>
            <span/>
            <span/>
          </div>
          {isMenuDisplayed && (
            <OverlayMenu pages={pages} pagesLocation={pagesLocation} cta={cta} onNavigate={() => this.setState({isMenuDisplayed: false})}/>
          )}
        </div>
      </div>
    )
  }
}

export default ({ data, path, disablePrimaryCta }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allContentfulHeader {
          nodes {
            logo {
              description
              file {
                url
              }
            }
            logoHeader {
              description
              file {
                url
              }
            }
            cta {
              texte
              lien
            }
            pages {
              path
              texte
            }
            pagesLocation {
              path
              texte
            }
          }
        }
      }
    `}
    render={data => <Header data={data.allContentfulHeader.nodes[0]} path={path} disablePrimaryCta={disablePrimaryCta}/>}
  />
)

Header.propTypes = {
  data: PropTypes.object.isRequired,
}
