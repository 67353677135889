import React from "react"
import Helmet from "react-helmet"

const Head = () =>
  <div>
    <Helmet>
      <html lang="fr" />
      {/* <!-- Primary Meta Tags --> */}
      <title>Pères et Fils Immobilier, la fabrique de vos projets immobiliers</title>
      <meta name="title" content="Pères et Fils Immobilier, la fabrique de vos projets immobiliers" />
      <meta name="description" content="Conseil en immobilier. Forts d’une expertise de 30 ans, nous vous aidons à mettre en oeuvre vos projets d’acquisition, de cession ou de gestion de vos biens et nous réfléchissons avec vous sur votre stratégie immobilière à travers un service sur-mesure." />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.peresetfilsimmobilier.fr/" />
      <meta property="og:title" content="Pères et Fils Immobilier, la fabrique de vos projets immobiliers" />
      <meta property="og:description" content="Conseil en immobilier. Forts d’une expertise de 30 ans, nous vous aidons à mettre en oeuvre vos projets d’acquisition, de cession ou de gestion de vos biens et nous réfléchissons avec vous sur votre stratégie immobilière à travers un service sur-mesure." />
      <meta property="og:image" content="https://peresetfilsimmobilier.fr/amicale_metatags.png" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.peresetfilsimmobilier.fr/" />
      <meta property="twitter:title" content="Pères et Fils Immobilier, la fabrique de vos projets immobiliers" />
      <meta property="twitter:description" content="Conseil en immobilier. Forts d’une expertise de 30 ans, nous vous aidons à mettre en oeuvre vos projets d’acquisition, de cession ou de gestion de vos biens et nous réfléchissons avec vous sur votre stratégie immobilière à travers un service sur-mesure." />
      <meta property="twitter:image" content="https://peresetfilsimmobilier.fr/amicale_metatags.png" />
    </Helmet>
  </div>

export default Head